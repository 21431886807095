.App {
    text-align: center;
}

.App-header {
    background-color: #ECF0FC;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.page-width {
    width: 1450px !important;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 50px;
}

.page-width.dim {
    background-color: rgba(40, 44, 52, 0.6);
}

table {
    width: 100%;
    border-collapse: collapse;
}

.title {
    width: 800px;
    font-size: larger;
    display: flex;
    justify-content: center;
    margin-left: 22%;
    margin-right: 22%;
    margin-top: 1%;
}

.openingButtons {
    margin: 20px auto;
    width: 320px;
    height: 320px;
    display: grid;
    grid-template-columns: 150px 150px;
    grid-row: 150px 150px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    align-self: center;

    .gameOption {
        background-color: #D3D3D3;
        border-color: black;
        padding: 20px;
        border-radius: 10px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-family: sans-serif;
        cursor: pointer;
    }

}

.game-board {
    display: grid;
    grid-template-columns: 110px 110px 110px 110px;
    grid-template-rows: 110px 110px 110px 110px;
    justify-content: center;
    margin-bottom: 15px;
}

.cell {
    background-color: #d9d9d9;
    border: 2px solid black;
}


.cell button {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 40px;
    font-weight: bold;
    color: black;
}

.displayBoard {
    display: grid;
    grid-template-columns: 50% 50%;
    content: center;
    margin: auto;
    width: 440px;
}

.pointsBlock {
    text-align: left;
    font-size: 80%;
}

.livesBlock {
    text-align: right;
    font-size: 80%;
}

.gifBox {
    display: grid;
    grid-template-columns: 40% 40%;
    content: center;
    margin: auto;
    margin-left: 25%;
    margin-bottom: 20px;

    .gif {
        content: center;
        max-width: 100px;
    }
}