.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.6); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    background: white;
    width: 80%;
    max-width: 550px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 10;
    /* Ensure modal content is centered */
    position: relative;
    top: 15%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.newButtons {
    display: grid;
    justify-content: center;
    margin: auto;
}

.newButtons.oneButton {
    grid-template-columns: 20%;
}

.newButtons.twoButtons {
    grid-template-columns: 20% 20%;
    gap: 15px;
}